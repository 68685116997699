

















import { defineComponent } from "@vue/composition-api";
import { Field, Button, Popup, DatetimePicker, Toast } from "vant";
import axios from "@/helpers/axios";
import moment from "moment";

export default defineComponent({
  props: {
    exportPlatformName: {
      type: String,
      default: ""
    },
    exportPlatformId: {
      type: Number,
      default: 0
    },
    exportBusinessName: {
      type: String,
      default: ""
    },
    exportBusinessId: {
      type: Number,
      default: 0
    },
    withFlow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      exportStartTime: "",
      exportEndTime: "",
      exportEmail: "",
      datePickerTarget: "",
      showDatePicker: false
    };
  },
  methods: {
    async doExport() {
      if (!this.exportStartTime || !this.exportEndTime) {
        this.$toast("请选择时间");
        return;
      }
      if (!this.exportEmail || !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.exportEmail)) {
        this.$toast("请正确填写邮箱");
        return;
      }

      await axios
        .post("/api/manage/levy/export", {
          platformId: this.exportPlatformId,
          businessId: this.exportBusinessId,
          startDate: this.exportStartTime,
          endDate: this.exportEndTime,
          email: this.exportEmail,
          withFlow: this.withFlow
        })
        .then(() => {
          Toast.success("导出成功");
          this.$emit("close");
        })
        .catch(() => undefined);
    },
    doShowDatePicker(target: string) {
      this.datePickerTarget = target;
      this.showDatePicker = true;
    },
    confirmDate(date: Date) {
      const time = moment(date).format("YYYY-MM-DD");
      this.showDatePicker = false;
      if (this.datePickerTarget === "exportStartTime") {
        this.exportStartTime = time;
      } else {
        this.exportEndTime = time;
      }
    }
  },
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker
  }
});
