


























import { defineComponent } from "@vue/composition-api";
import { CellGroup, Cell, Collapse, CollapseItem, Field, Popup, DatetimePicker, Button, Divider, Icon, Image, ImagePreview, Dialog, Radio, RadioGroup } from "vant";
import LevyExport from "./levy-export.vue";
import axios from "@/helpers/axios";

export default defineComponent({
  data() {
    return {
      accounts: [] as { id: number; amount: number; platformId: number; platformName: string; businessId: number; businessName: string; notHandledRechargeOrderId: number }[],
      activeNames: [],
      showExportPopup: false,
      exportPlatformId: 0,
      exportPlatformName: "",
      exportBusinessId: 0,
      exportBusinessName: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const accounts = await axios
        .post("/api/manage/levy/accounts")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!accounts) return;
      this.accounts = accounts;
    },
    toRechargeOrderPage(id: number) {
      this.$router.push("/levy/levy-recharge?id=" + id);
    },
    doShowExportPopup(account: { platformName: string; platformId: number; businessName: string; businessId: number }) {
      this.showExportPopup = true;
      this.exportPlatformName = account.platformName;
      this.exportPlatformId = account.platformId;
      this.exportBusinessId = account.businessId;
      this.exportBusinessName = account.businessName;
    }
  },
  components: {
    LevyExport,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup
  }
});
